import * as React from 'react'
/* This example requires Tailwind CSS v2.0+ */
import Layout from "../../components/layout"
import ProductOverview from '../../components/productoverview'
import ProductImage from "../../images/Kontaktlinsvatska_EXTEND-1-600bred.webp"

// TODO Extend - Optimize images with Gatsby StaticImage 
// TODO Extend - Links to Saline Solutions at bottom of page

const extendDetails =
{
  productName: 'EXTEND',
  productDescription: 'Kontaktlinsvätska',
  productImage: ProductImage,
  intendedUsages:
  [
    {name: '1', usage: 'Extend is a solution used for cleaning, rinsing, disinfecting and the storage of contact lenses of all types of lens material.'},
  ],
  contentDescription: 'Extend is a sterile isotonic solution containing the following',
  contents:
  [
    {name: '1', content: 'Borate Buffer'},
    {name: '2', content: 'Disodium EDTA'},
    {name: '3', content: 'Sodium chloride'},
    {name: '4', content: 'Active ingredients: chlorhexidine gluconate 0.001%, poloxamine 1%'},
  ],
  contentDisclaimer: 'Does not contain Thiomersal.',
  sizes:
  [
    {name: '1', sizeDescription: '355 ml bottles, which come with a free lens case'},
    {name: '2', sizeDescription: '100 ml bottles, perfect for the office and travelling'},
  ],
  deviceDisclaimer: 'The bottle must be discarded after it has been opened for 6 months.',
}

export const Head = () => (
  <>
    <title>EXTEND Contact Lens Solution</title>
    <meta name="description" content="Consol AB | Manufacturer of medical devices for lens care and eye care" />
  </>
)

export default function Page() {
  return (
    <Layout>
        <main className="bg-white pt-8">
            <ProductOverview productDetails={extendDetails} />
        </main>
    </Layout>
  )
}




